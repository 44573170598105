// Libraries
import { useState } from "react";
import i18next from 'i18next';
import axios from 'axios';
import { t } from "i18next";
import { isEmail } from "./../utils/businessUtils.js"
import voucherSujets from './voucherSujets.json';
import voucherPayments from './voucherPayments.json';
import voucherShippings from './voucherShippings.json';
import LoadTwoRowCustomerFilter from './../therapist/filters/loadTwoRowCustomerFilter.js'
import LangLink from './../translations/LangLink.js'
import Account from "../login/account";

// CSS
import "./vouchers.css";

// Images
import Gift from "./img/gift.svg";
import Presents from "./img/presents.svg";
import Presents2 from "./img/present2.svg";
import apple from "./img/applepay.png";
import google from "./img/googlepay.png";
import twint from "./img/twint.png";
import mastercard from "./img/mastercard.png";

export default function Vouchers({ loginUser, updateLoginUser, logout, setPopup }) {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    paiedBy: null,
    voucherFrom: loginUser == null ? null : loginUser["firstname"], // + " " + loginUser["lastname"]
    voucherTo: null,
    voucherAmount: null,
    sujet: 0,
    payment: 0,
    shipping_id: 0,
    recipient_address: null,
    recipient_email: null,
  });
  const [voucherRequestSent, setVoucherRequestSent] = useState(false);
  const languageCodes = Object.keys(i18next.options.resources);

  const handleNext = () => {
    if (loginUser == null) {
      setPopup("login");
    }
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const cashTransactionsUrl = `/api/voucher.php?action=request`;
    axios.post(cashTransactionsUrl, formData)
    .then((data) => {
      if (data.status === "success") {
        console.log(data.status);
      }
      setVoucherRequestSent(true);
    })
    .catch(error => {
        console.error('Error:', error);
    });
  };

  const handlePropertyValueChange = (property, value) => {
    setFormData({ ...formData, [property]: value });
  };

  const handleChange = (e) => {
    e.target.focus(); //required together with onBlur to take effect
    const { name, value, nodeName } = e.target;
    if (nodeName === "SELECT") {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const selectedOptionId = selectedOption.getAttribute("value");
        handlePropertyValueChange(name, selectedOptionId);
    } else {
        handlePropertyValueChange(name, value);
    }
  };

  const selectedPayment = voucherPayments.find(payment => payment.id === Number(formData.payment));

  if (voucherRequestSent) {
    return (
      <div>
        <div className="voucher-cover">
          <h1>{t('voucher-for-friends-family')}</h1>
        </div>
        <div className="surprise">
          <div className="text-box">
            <br></br>
            <p>
              {t('voucher-request-received')}
            {formData.payment == 2 ? (
              <span> {t('voucher-received-instruction-invoice')}</span>
            ) : (
              <span> {t('voucher-received-instruction-general')}</span>
            )}
            </p>
          </div>
        </div>
      </div>
    );
  }

  const formFields = [
    { tag: "input", 
      name: "voucherFrom", 
      type: "text", 
      label: t('voucher-from-name'), 
      value: formData.voucherFrom || ''
    },
    { tag: "input", 
      name: "voucherTo", 
      type: "text", 
      label: t('voucher-for-name'), 
      value: formData.voucherTo || '', 
      additionalContent: <p className="left">{t('voucher-optional-name-note')}</p>
    },
    { tag: "select", 
      name: "sujet", 
      label: t('voucher-subject') + " *", 
      options: voucherSujets, 
      value: formData.sujet || 0, 
      required: true 
    },
    { tag: "input", 
      name: "voucherAmount", 
      type: "number", 
      label: t('voucher-value-chf'), 
      value: formData.voucherAmount || '', 
      required: true 
    },
    { tag: "select", 
      name: "payment", 
      label: t('voucher-payment') + " *", 
      options: voucherPayments, 
      value: formData.payment, 
      required: true,
      additionalContent:
        <>
          {selectedPayment && selectedPayment.description && (
            <p className="left">{t(selectedPayment.description)}</p>
          )}
        </>
    },
    { tag: "select", 
      name: "shipping_id", 
      label: t('voucher-shipping') + " *", 
      options: voucherShippings, 
      value: formData.shipping_id || 0, 
      required: true 
    },
    { tag: "input", 
      name: "recipient_email", 
      type: "email", 
      label: t('voucher-shipping-recipient') + " " + t('voucher-shipping-recipient-email-address') + " *", 
      value: formData.recipient_email || '', 
      required: true,
      condition: () => formData.shipping_id == 2,
    },
    { tag: "input", 
      name: "recipient_address", 
      type: "text", 
      label: t('voucher-shipping-recipient') + " " + t('voucher-shipping-recipient-physical-address') + " *", 
      value: formData.recipient_address || '', 
      required: true,
      condition: () => formData.shipping_id == 4,
    },
  ];

  let therapistPanel = null;
  if (loginUser != null && loginUser["is_therapist"]) {
    const voucherRequestLinks = languageCodes.map((langCode) => {
      return (
        <>
          <LangLink key={"voucher-request-" + langCode} className="underline" to={`/api/pdf/voucher-request.php?language_code=${langCode}&paiedBy=${formData.paiedBy}`}  target='blank'>
            {t(`language-${langCode}`)}
          </LangLink> <span> </span>
        </>
      )
    });

    therapistPanel = <div className="form-group">
      <label htmlFor="payment">{t('voucher-buyer')} *</label>
      <p className="left">{t('voucher-therapist-only-select-buyer')}</p>
      <LoadTwoRowCustomerFilter 
        loginUser={loginUser}
        logout={logout}
        allCustomerFilterAvailable={false}
        filterEmail={formData.paiedBy}
        setFilterEmail={(email) => handlePropertyValueChange("paiedBy", email)}
        choicePerRow={4}>
      </LoadTwoRowCustomerFilter>
      <p className="left">{t('voucher-request')} PDF: {voucherRequestLinks}</p>
    </div>
  }
  
  return (
    <div>
      <div className="voucher-cover">
        <h1>{t('voucher-for-friends-family')}</h1>
      </div>
      <h1 className="text-box">{t('voucher-health-wellbeing')}</h1>
      <div className="surprise">
      <div className="surprise-left">
          <h2 className="text-box" >{t('voucher-gift-experience')}</h2>
          <div className="text-box">
            <p>{t('voucher-online-payment-not-available')}</p>
            <p>{t('voucher-payment-availability')}:</p>
          </div>
          <div className="method-box">
            <div className="method-list">
              <img src={twint} alt="payment method twint" />
              <img src={mastercard} alt="payment method mastercard" />
              <img src={google} alt="payment method google" />
              <img src={apple} alt="payment method apple" />
            </div>
          </div>
          {/* <div className="text-box">
            <h2>{t('voucher-two-for-one')}</h2>
            <p>{t('voucher-two-for-one-remarks')}</p>
          </div> */}
        </div>
        <div className="surprise-right">
          <img src={Gift} alt="gift voucher"></img>
        </div>
      </div>
      <div className="voucher-form">
        <img id="present" src={Presents} alt="presents gifts massage" />
        <img id="present2" src={Presents2} alt="presents gifts massage" />
        <form onSubmit={handleSubmit}>
          {currentStep === 1 && (
            <div className="slide">
              <h1>{t('voucher-request')}</h1>

              {formFields.map(({ tag, name, type, label, value, options, additionalContent, required, condition }) => {
                if (condition) {
                  if (!condition()) return null;
                }
                return (
                <div className="form-group" key={name}>
                  <label htmlFor={name}>{label}</label>
                  {tag === "input" ? (
                    <input
                      type={type}
                      id={name}
                      name={name}
                      value={value}
                      onChange={handleChange}
                      required={required}
                    />
                  ) : (
                    <select
                      id={name}
                      name={name}
                      onChange={handleChange}
                      value={value}
                      required={required}
                    >
                      {options.map(option => (
                        <option key={option.id} value={option.id}>
                          {t(option.label)}
                        </option>
                      ))}
                    </select>
                  )}
                  {additionalContent}
                </div>
              )})}
              {therapistPanel}
              
              <div className="form-group">
                {formData && 
                formData["voucherAmount"] > 0 && 
                formData["sujet"] > 0 && 
                formData["payment"] > 0 && 
                formData["shipping_id"] > 0 &&
                ( formData["shipping_id"] != 2 && formData["shipping_id"] != 4 || 
                  formData["shipping_id"] == 2 && formData["recipient_email"] && isEmail(formData.recipient_email) ||
                  formData["shipping_id"] == 4 && formData["recipient_address"] 
                ) ? (
                  <button type="button" onClick={handleNext}>
                    {t('next')}
                  </button>
                ) : (
                  <p className="left">
                    {t('voucher-fill-required-fields')}
                  </p>
                )}
              </div>
            </div>
          )}

          {currentStep === 2 ? (
            loginUser == null ? (
              <div className="slide">
                <p>
                  {t('voucher-register-login')}
                </p>
                <button type="button" onClick={() => setPopup("login")}>
                  {t('login')}
                </button>
                <button type="button" onClick={handleBack}>
                  {t('back')}
                </button>
              </div>
            ) : (

            <div className="slide">
              <Account loginUser={loginUser} logout={logout} updateLoginUser={updateLoginUser} minimal={true}></Account>
              <button type="button" onClick={handleBack}>
                {t('back')}
              </button>
              <button type="submit">
                {t('submit')}
              </button>
            </div>
          )) : null}
        </form>
      </div>
    </div>
  );
}
