// Libraries
import { useEffect, useRef, useState } from "react";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import voucherSujets from '../../vouchers/voucherSujets.json';
import voucherPayments from '../../vouchers/voucherPayments.json';
import voucherShippings from '../../vouchers/voucherShippings.json';
import { getUserDisplay } from './../../utils/businessUtils.js';
import SelectedVoucherComp from './selectedVoucher.js'
import { dateAndTime } from './../../utils/dateTimeUtils.js'
import ProcessingCircle from './../../utils/processingCircle.js';

//CSS
import './voucherList.css';

function VoucherList({ loginUser, logout, showArchive }) {
    const { t } = useTranslation();
    const isFetchingRef = useRef(false);
    const [vouchers, setVouchers] = useState(null);
    const [selectedVoucher, setSelectedVoucher] = useState(null);

    const loadVoucherList = async () => {
        const url = `/api/voucher.php?action=list&showArchive=` + showArchive;
        axios.get(url)
        .then((data) => {
            if (data.data.Status === "success") {
                setVouchers(data.data.vouchers);
            }

            isFetchingRef.current = false;
        })
        .catch(error => {
            console.error('Error:', error);
            logout();
        });
    };

    const findLabelById = (id, options) => {
        const option = options.find(opt => opt.id === id);
        return option ? t(option.label) : '';
    };

    const voucherEdit = (voucherId) => {
        setSelectedVoucher(vouchers.find(v => v.id === voucherId));
    }

    useEffect(() => {
        // Ensure to load only once
        if (isFetchingRef.current)
            return;
        else
            isFetchingRef.current = true;
            loadVoucherList();
    }, []);

    if (loginUser == null) {
        return <h2>{t("data-unauthenticated")}</h2>
    }

    if (vouchers == null) {
        return <div>
            <h2>{t('data-loading')}</h2>
            <ProcessingCircle></ProcessingCircle>
        </div>
    }

    if (vouchers.length <= 0) {
        return <h2>{t("data-none")}</h2>
    }

    const rows = vouchers.map((voucher) => {
        const voucherClass = "voucher-manage-row" + (selectedVoucher && selectedVoucher.id === voucher.id ? " selected-voucher" : "");
        const creationDate = new Date(voucher.creation_date);
        return (
            <tr key={"voucher-" + voucher.id} className={voucherClass} onClick={() => voucherEdit(voucher.id)}>
                <td>{dateAndTime(creationDate)}</td>
                <td>{"V" + voucher.id.toString().padStart(6, '0')}<br></br><span className="smaller-font">{voucher.code}</span></td>
                <td>{getUserDisplay(voucher)}</td>
                <td>{findLabelById(voucher.payment, voucherPayments)}</td>
                {/* <td>{voucher.voucherFrom}</td>
                <td>{voucher.voucherTo}</td> */}
                <td>{findLabelById(voucher.sujet, voucherSujets)}</td>
                <td>{findLabelById(voucher.shipping_id, voucherShippings)}</td>
                <td>{voucher.voucherSaldo}<br></br><span className="smaller-font">{voucher.voucherAmount}</span></td>
            </tr>
        );
    })

    return (
        <div>
            <h3>
                {showArchive ? t('voucher-archive') : t('voucher-list')}
            </h3>
            <table className="voucher-table">
                <thead>
                    <tr>
                        <th>{t('request-date')}</th>
                        <th>{t('number')}</th>
                        <th>{t('code')}<br></br>{t('voucher-buyer')}</th>
                        <th>{t('voucher-payment')}</th>
                        {/* <th>{t('voucher-from-name')}</th>
                        <th>{t('voucher-for-name')}</th> */}
                        <th>{t('voucher-subject')}</th>
                        <th>{t('voucher-shipping')}</th>
                        <th>{t('balance')} [CHF]<br></br><span className="smaller-font">{t('initial-amount')}</span></th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </table>
            {selectedVoucher ? (
                <SelectedVoucherComp 
                    key={"voucher-display-" + selectedVoucher.id}
                    loginUser={loginUser} 
                    logout={logout} 
                    voucher={selectedVoucher}
                    loadVoucherList={loadVoucherList}>
                </SelectedVoucherComp>
            ) : null}
        </div>
    );
}

export default VoucherList;
